$color-font--lightgrey: #cbccce;

/* dimensions */
$body-scrollbar-width: 10px;

/* settings */
@import 'new/settings/+setting';

/* mixins */
@import 'new/mixins/mixins';

@mixin responsive() {
    @content;
    @each $name, $size in $breakpoints {
        @include media('>' + $name) {
            &-#{$name} {
                @content;
            }
        }
    }
}

/* font */
@import 'new/fonts/$iconfont';
@import 'new/fonts/$webfont';

/* resets */
@import 'new/reset/reset';

/* elements */
@import 'new/elements/headlines';
@import 'new/elements/inputs';

/* components */
@import 'new/modules/align/align';
@import 'new/modules/border/border';
@import 'new/modules/cursor/cursor';
@import 'new/modules/color/color';
@import 'new/modules/dimensions/height';
@import 'new/modules/dimensions/maxwidth';
@import 'new/modules/dimensions/maxheight';
@import 'new/modules/dimensions/minheight';
@import 'new/modules/dimensions/minwidth';
@import 'new/modules/dimensions/width';
@import 'new/modules/display/flex';
@import 'new/modules/display/grid';
@import 'new/modules/display/visibility';
@import 'new/modules/font/font-lineheight';
@import 'new/modules/font/font-size';
@import 'new/modules/font/font-weight';
@import 'new/modules/font/icons';
@import 'new/modules/font/font';
@import 'new/modules/font/text';
@import 'new/modules/image/image';
@import 'new/modules/index/zindex';
@import 'new/modules/margin/margin';
@import 'new/modules/margin/padding';
@import 'new/modules/media/media';
@import 'new/modules/object/object';
@import 'new/modules/opacity/opacity';
@import 'new/modules/overflow/overflow';
@import 'new/modules/position/position';
@import 'new/modules/radius/radius';
@import 'new/modules/ratio/ratio';
@import 'new/modules/shadow/shadow';
@import 'new/modules/transition/transition';

/* complex */
@import 'new/complex/accordeon';
@import 'new/complex/animation';
@import 'new/complex/complex';
@import 'new/complex/datepicker';
@import 'new/complex/jsoneditor';
@import 'new/complex/loading';
@import 'new/complex/logo';
@import 'new/complex/menu';
@import 'new/complex/pricing';
@import 'new/complex/sidebar';
@import 'new/complex/story';
@import 'new/complex/text';
@import 'new/complex/treeselect';
