@mixin do-margins($direction: '', $attr: '', $pseudo: '', $attr-2: '') {
    @if $direction != '' {
        $direction: '-' + $direction;
    }

    @if $attr != '' {
        $attr: '-' + $attr;
    }

    @each $name, $margin in $margins {
        &#{$direction}-#{$name} {
            @if $pseudo == '' {
                margin#{$attr}: $margin;
                @if $attr-2 != '' {
                    margin-#{$attr-2}: $margin;
                }
            } @else {
                &:#{$pseudo} {
                    margin#{$attr}: $margin;
                    @if $attr-2 != '' {
                        margin-#{$attr-2}: $margin;
                    }
                }
            }
        }
    }
}

.m {
    @include responsive() {
        @include do-margins('t', 'top');
        @include do-margins('l', 'left');
        @include do-margins('b', 'bottom');
        @include do-margins('r', 'right');
        @include do-margins('tb', 'top', '', 'bottom');
        @include do-margins('lr', 'left', '', 'right');
        @include do-margins();
    }
}

.before {
    &-m {
        @include do-margins('r', 'right', ':before');
    }
}
