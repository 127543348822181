/* general */
$color--white: #fff;
$color--black: #000;
$color--grey: #949494;
$color--lightgrey: #f6f6f8;
$color--lightgrey-darker-5: darken($color--lightgrey, 5%);
$color--lightgrey-darker-10: darken($color--lightgrey, 10%);
$color-bg--body: #f5f7fa;
$color-bg--body-dark-5: darken($color-bg--body, 5%);
$color-bg--body-dark-10: darken($color-bg--body, 10%);
$color--transparent: rgba(0, 0, 0, 0);
$color--transparent-1: rgba(0, 0, 0, .1);
$color--w-trans-4: rgba(256, 256, 256, .4);
$color--w-trans-8: rgba(256, 256, 256, .8);

/* primary */
$color--primary-1: #101a35;
$color--primary-2: #9e6bf8;
$color--primary-3: #0ac7ab;
$color--primary-4: #e6eaf7;
$color--primary-5: #f59200;

$color--primary-1-dark: darken($color--primary-1, 5%);
$color--primary-2-dark: darken($color--primary-2, 15%);
$color--primary-3-darker: darken($color--primary-3, 10%);
$color--primary-3-lighter: lighten($color--primary-3, 35%);
$color--primary-3-light: lighten($color--primary-3, 55%);
$color--primary-4-dark: darken($color--primary-4, 30%);
$color--primary-5-light: lighten($color--primary-5, 35%);
$color--primary-5-dark: darken($color--primary-5, 10%);

/* secondary */
$color--sec-1: #2714de;
$color--sec-2: #173efa;
$color--sec-3: #9514de;
$color--sec-4: #e617fa;
$color--sec-5: #581bc2;
$color--sec-6: #9f6cf7;
$color--sec-7: #f59f3b;
$color--sec-8: #0fa82d;
$color--sec-9: #f2f3f5;

/* status */
$color-status--ok: #00de7e;
$color-status--warning: #ff830d;
$color-status--error: #ff755f;
$color-status--star: #ffcd54;

/* font */
$color-font: $color--primary-1-dark;
$color-font-grey: #717171;
$color-font--lightgrey: #cbccce;
$color-font--darkgrey: #434863;

/* border */
$color-border--grey: #f0f1f5;
$color-border--grey-2: #e8e8ed;

$colors: (
    'trans': rgba(0, 0, 0, 0),
    'trans-1': $color--transparent-1,
    'w': $color--white,
    'b': $color--black,

    'pr-1': $color--primary-1,
    'pr-2': $color--primary-2,
    'pr-3': $color--primary-3,
    'pr-4': $color--primary-4,
    'pr-5': $color--primary-5,

    'pr-2-dark': $color--primary-2-dark,
    'pr-3-darker': $color--primary-3-darker,
    'pr-3-lighter': $color--primary-3-lighter,
    'pr-3-light': $color--primary-3-light,
    'pr-4-dark': $color--primary-4-dark,

    'se-1': $color--sec-1,
    'se-2': $color--sec-2,
    'se-3': $color--sec-3,
    'se-4': $color--sec-4,
    'se-5': $color--sec-5,
    'se-6': $color--sec-6,
    'se-7': $color--sec-7,
    'se-8': $color--sec-8,
    'se-9': $color--sec-9,

    'stat-ok': $color-status--ok,
    'stat-warn': $color-status--warning,
    'stat-er': $color-status--error,
    'stat-gold': $color-status--star,

    'font': $color-font,
    'font-grey': $color-font-grey,
    'font-lightgrey': $color-font--lightgrey,
    'font-darkgrey': $color-font--darkgrey,
    'lightgrey': $color--lightgrey,
    'lightgrey-dark-5': $color--lightgrey-darker-5,
    'lightgrey-dark-10': $color--lightgrey-darker-10,
    'body': $color-bg--body,
    'body-dark-5': $color-bg--body-dark-5,
    'body-dark-10': $color-bg--body-dark-10,
    'grey': $color--grey,
    'border-grey': $color-border--grey,
    'border-grey-2': $color-border--grey-2,
    'w-trans-4': $color--w-trans-4
);

/* gradient colors */
$color-gradient--2to3: linear-gradient(to right, $color--primary-2, $color--primary-3);
$color-gradient--3to2: linear-gradient(to right, $color--primary-3, $color--primary-2);
$color-gradient--pri2dark-to-orange: linear-gradient(to right, $color--primary-2-dark, $color-status--warning);

$colors-grad: (
    'pr-2-to-pr-3': $color-gradient--2to3,
    'pr-3-to-pr-2': $color-gradient--3to2,
    'pr-2-dark-to-orange': $color-gradient--pri2dark-to-orange
);
