.complex {
    &-main {
        height: calc(100vh - 3.75rem);

        &.is--backend {
            height: calc(100vh - 3rem);
        }
    }
    
    &-search {
        &-map {
            height: calc(100% - 10rem);
        }
    }
}
