/*
 * ### global headline config (start)
 */

/* headlines */
// screen
$dimensions-headline--h1: 3.438rem; // 55px
$dimensions-headline--h2: 2.813rem; // 45px
$dimensions-headline--h3: 1.875rem; // 30px
$dimensions-headline--h4: 1.375rem; // 22px
$dimensions-headline--h5: 1.125rem; // 18px
$dimensions-headline--h6: 1rem;     // 16px

// wide
$dimensions-headline--h1-wide: $dimensions-headline--h1;

//mobile
$dimensions-headline-mobile--h1: $dimensions-headline--h2;
$dimensions-headline-mobile--h2: $dimensions-headline--h3;
$dimensions-headline-mobile--h3: $dimensions-headline--h4;
$dimensions-headline-mobile--h4: $dimensions-headline--h5;
$dimensions-headline-mobile--h5: $dimensions-headline--h6;
$dimensions-headline-mobile--h6: $dimensions-headline--h6;
