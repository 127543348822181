.fl {
    @include responsive() {
        display: flex;

        &-wrap {
            flex-wrap: wrap;

            &-none {
                flex-wrap: nowrap;
            }
        }
        
        &-shrink {
            &-none {
                flex-shrink: 0;
            }
        }

        &-a {
            &-items {
                &-top {
                    align-items: flex-start;
                }

                &-center {
                    align-items: center;
                }

                &-bottom {
                    align-items: flex-end;
                }

                &-stretch {
                    align-items: stretch;
                }

            }
        }

        &-jc {
            &-start {
                justify-content: flex-start;
            }

            &-center {
                justify-content: center;
            }

            &-end {
                justify-content: flex-end;
            }

            &-sb {
                justify-content: space-between;
            }

            &-sa {
                justify-content: space-around;
            }

            &-stretch {
                justify-content: stretch;
            }
        }

        &-direction {
            &-col {
                flex-direction: column;
            }

            &-col-rev {
                flex-direction: column-reverse;
            }

            &-row {
                flex-direction: row;
            }

            &-row-rev {
                flex-direction: row-reverse;
            }
        }
    }
}
