/* breakpoints */
$breakpoints: (
    'sm': 48rem,     // 768px
    'xme': 55.63rem, // 890px
    'me': 64rem,     // 1024px
    'l': 90rem       // 1440px
);

$margin--2: .125rem;
$margin--4: .25rem;
$margin--6: .375rem;
$margin--8: .5rem;
$margin--10: .625rem;
$margin--14: .875rem;
$margin--16: 1rem;
$margin--20: 1.25rem;
$margin--22: 1.375rem;
$margin--24: 1.5rem;
$margin--30: 1.875rem;
$margin--38: 2.375rem;
$margin--70: 4.375rem;

/* margins */
$margins: (
    'none': 0,
    '2': $margin--2,      // 2px
    '4': $margin--4,      // 4px
    '6': $margin--6,      // 6px
    '8': $margin--8,      // 8px
    '10': $margin--10,    // 10px
    '14': $margin--14,    // 14px
    '16': $margin--16,    // 16px
    '20': $margin--20,    // 20px
    '22': $margin--22,    // 22px
    '24': $margin--24,    // 24px
    '30': $margin--30,    // 30px
    '38': $margin--38,    // 38px
    '70': $margin--70,    // 70px
);

/* border */
$dimensions-border--small: .063rem;
$dimensions-border--default: .125rem;
$dimensions-border--xdefault: .188rem;
$dimensions-border--big: .25rem;

$border-widths: (
    'none': 0,
    '1': $dimensions-border--small,      // 1px
    '2': $dimensions-border--default,    // 2px
    '3': $dimensions-border--xdefault,   // 3px
    '4': $dimensions-border--big         // 4px
);

$transition-duration-small: .1s;
$transition-duration: .3s;
$transiion-easing--default: cubic-bezier(.77, 0, .175, 1);
$transition-default: $transition-duration $transiion-easing--default;
$transition-default-small: $transition-duration-small $transiion-easing--default;

$dimensions-border-radius--3: .188rem;
$dimensions-border-radius--6: .375rem;
$dimensions-border-radius--16: 1rem;
$dimensions-border-radius--32: 2rem;

@import '+headlines';
@import '+color';
@import '+font';
@import '+shadow';
@import 'components/+header';
