.maxh {
    @include responsive() {
        &-100vh {
            max-height: 100vh;
        }
        
        &-10rem {
            max-height: 10rem;
        }
        
        &-30rem {
            max-height: 30rem;
        }
    }
}
