.vis {
    @include responsive() {
        &-none {
            display: none;
        }

        &-block {
            display: block;
        }

        &-inline {
            display: inline-block;
        }

        &-flex {
            display: flex;
        }
    }
}

.after-vis {
    @include responsive() {
        &-none {
            display: none;
        }
    }
}

.empty {
    &-vis {
        &-none {
            &:empty {
                display: none;
            }
        }
    }
}
