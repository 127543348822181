h1,
h2,
h3,
h4 {
    letter-spacing: -.0125em;
}

h1 {
    font-size: $dimensions-headline--h1;
    font-weight: 900;
}

h2 {
    font-size: $dimensions-headline--h2;
}

h3 {
    font-size: $dimensions-headline--h3;
}

h4 {
    font-size: $dimensions-headline--h4;
}

h5 {
    font-size: $dimensions-headline--h5;
}

h6 {
    font-size: $dimensions-headline--h6;
}
