.h {
    @include responsive() {
        &-auto {
            height: auto;
        }
        
        &-min-100vh {
            min-height: 100vh;
        }
    
        &-26px {
            height: 1.625rem;
        }

        &-44px {
            height: 2.75rem;
        }
        
        &-60vh {
            height: 60vh;
        }

        &-100vh {
            height: 100vh;
        }

        &-100 {
            height: 100%;
        }
    
        &-44px {
            height: 2.75rem;
        }
    
        &-2rem {
            height: 2rem;
        }
        
        &-3rem {
            height: 3rem;
        }
        
        &-4rem {
            height: 4rem;
        }
    
        &-6rem {
            height: 6rem;
        }
    
        &-8rem {
            height: 8rem;
        }

        &-10rem {
            height: 10rem;
        }

        &-15rem {
            height: 15rem;
        }

        &-20rem {
            height: 20rem;
        }
    }

    &-60px {
        height: 3.75rem;
    }
}
