.complex {
    &-floatingbox {
        @include position(fixed, $top: $header-dimensions-bottom-height + $header-dimensions-middle-height + $header-dimensions-top-height);
        z-index: 100;
    }
    
    &-sidebar {
        nav {
            li {
                a {
                    margin-bottom: $margin--6;
                    
                    &:before {
                        font-size: $font-size--18;
                        width: 1.5rem;
                    }
                }
            }
        }
    }
}
