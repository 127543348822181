.minw {
    @include responsive() {
        &-10rem {
            min-width: 10rem;
        }
        
        &-14rem {
            min-width: 14rem;
        }
        
        &-15rem {
            min-width: 15rem;
        }
    
        &-30rem {
            min-width: 30rem;
        }

        &-inherit {
            min-width: inherit;
        }
    }
}
