.transition {
    &-de {
        transition: all $transition-duration $transiion-easing--default; // sass-lint:disable-line no-transition-all

        &-shad {
            transition: box-shadow $transition-duration $transiion-easing--default;
        }

        &-color {
            transition: color $transition-duration $transiion-easing--default;
        }

        &-bg {
            transition: background-color $transition-duration $transiion-easing--default;
        }

        &-width {
            transition: width $transition-duration $transiion-easing--default;
        }
    }

    &-small {
        &-color {
            transition: color $transition-duration-small $transiion-easing--default;
        }

        &-bg {
            transition: background-color $transition-duration-small $transiion-easing--default;
        }
    }
}
