.ratio {
    @include responsive() {
        &-4_3 {
            padding-bottom: 75%;
        }

        &-2_1 {
            padding-bottom: 50%;
        }

        &-1_1 {
            padding-bottom: 100%;
        }
    }
}
