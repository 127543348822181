.mw {
    @include responsive() {
        &-2_3 {
            width: 66.6666%;
        }

        &-100 {
            max-width: 100%;
        }

        &-75 {
            max-width: 75%;
        }

        &-50 {
            max-width: 50%;
        }
    
        &-10rem {
            max-width: 10rem;
        }
    
        &-20rem {
            max-width: 20rem;
        }
    
        &-25rem {
            max-width: 25rem;
        }

        &-40rem {
            max-width: 40rem;
        }

        &-45rem {
            max-width: 45rem;
        }
        
        &-62rem {
            max-width: 63rem;
        }
    
        &-content {
            max-width: 75rem;
        }

        &-100rem {
            max-width: 100rem;
        }

        &-350px {
            max-width: 21.875rem;
        }
    
        &-800px {
            max-width: 50rem;
        }
    }
}
