.gr {
    @include responsive() {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        &-a {
            &-items {
                &-top {
                    align-items: flex-start;
                }

                &-center {
                    align-items: center;
                }

                &-bottom {
                    align-items: flex-end;
                }
            }
        }

        &,
        &-12 {
            grid-template-columns: repeat(12, 1fr);
        }

        &-6 {
            grid-template-columns: repeat(6, 1fr);
        }

        &-4 {
            grid-template-columns: repeat(4, 1fr);
        }

        &-3 {
            grid-template-columns: repeat(3, 1fr);
        }

        &-2 {
            grid-template-columns: repeat(2, 1fr);
        }

        &-1 {
            grid-template-columns: repeat(1, 1fr);
        }

        &-span {
            &-2 {
                grid-column: span 2;
            }

            &-3 {
                grid-column: span 3;
            }

            &-4 {
                grid-column: span 4;
            }

            &-5 {
                grid-column: span 5;
            }

            &-6 {
                grid-column: span 6;
            }

            &-7 {
                grid-column: span 7;
            }

            &-8 {
                grid-column: span 8;
            }

            &-9 {
                grid-column: span 9;
            }

            &-10 {
                grid-column: span 10;
            }

            &-11 {
                grid-column: span 11;
            }

            &-12 {
                grid-column: span 12;
            }
        }

        &-colend {
            &-1 {
                grid-column-end: 1;
            }

            &-2 {
                grid-column-end: 2;
            }

            &-3 {
                grid-column-end: 3;
            }

            &-4 {
                grid-column-end: 4;
            }

            &-5 {
                grid-column-end: 5;
            }

            &-6 {
                grid-column-end: 6;
            }

            &-7 {
                grid-column-end: 7;
            }

            &-8 {
                grid-column-end: 8;
            }

            &-9 {
                grid-column-end: 9;
            }

            &-10 {
                grid-column-end: 10;
            }

            &-11 {
                grid-column-end: 11;
            }

            &-12 {
                grid-column-end: 12;
            }
        }

        &-colstart {
            &-1 {
                grid-column-start: 1;
            }

            &-2 {
                grid-column-start: 2;
            }

            &-3 {
                grid-column-start: 3;
            }

            &-4 {
                grid-column-start: 4;
            }

            &-5 {
                grid-column-start: 5;
            }

            &-6 {
                grid-column-start: 6;
            }

            &-7 {
                grid-column-start: 7;
            }

            &-8 {
                grid-column-start: 8;
            }

            &-9 {
                grid-column-start: 9;
            }

            &-10 {
                grid-column-start: 10;
            }

            &-11 {
                grid-column-start: 11;
            }

            &-12 {
                grid-column-start: 12;
            }
        }

        &-cg {
            column-gap: 1rem;
        }

        &-rg {
            row-gap: 1rem; // sass-lint:disable-line no-misspelled-properties
        }
    }
}
