.zindex {
    &-1 {
        z-index: 1;
    }

    &-10 {
        z-index: 10;
    }

    &-100 {
        z-index: 100;
    }
    
    &-300 {
        z-index: 300;
    }
    
    &-1000 {
        z-index: 1000;
    }
    
    &-overlay {
        z-index: 99999;
    }
}
