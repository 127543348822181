.overflow {
    @include responsive() {
        &-hidden {
            overflow: hidden;
        }
    
        &-scroll-y {
            -webkit-overflow-scrolling: touch;
            overflow-y: scroll;
        }
        
        &-small {
            @include scrollbar(10px);
        }
    }
}
