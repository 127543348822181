.ani {
    &-fadeindelay {
        animation: 5s fadeInDelay ease;
    }

    &__image {
        &-scale {
            img {
                scale: 1.01;
                transition: scale $transition-default;
            }

            &:hover {
                img {
                    scale: 1.1;
                }
            }
        }
    }
}
