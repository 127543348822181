.f-lh {
    @include responsive() {
        &-1 {
            line-height: $font-lh--1;
        }

        &-sm {
            line-height: $font-lh--1-2;
        }

        &-de {
            line-height: $font-lh--1-6;
        }

        &-2 {
            line-height: $font-lh--2;
        }
        
        &-18px {
            line-height: 1.125rem;
        }
    }
}
