[data-class='is--json'] {
    .btn {
        &,
        span {
            color: $color--white;
        }
    }

    .form-group {
        padding-bottom: $margin--10;
    }

    label {
        display: block;
        padding: 0 $margin--6 $margin--6;
    }

    .form-control {
        margin: 0;
        padding-left: $margin--6;

        &:not(:focus) {
            background-color: $color--white;
            border-color: $color-font-grey;
        }

        &:focus {
            background-color: $color-bg--body;
        }

        &:hover {
            background-color: darken($color-bg--body, 2%);
        }
    }

    .je-switcher {
        margin-bottom: $margin--10;
    }
}
