.rad {
    @include responsive() {
        &-none {
            border-radius: 0;
        }

        &-3 {
            border-radius: $dimensions-border-radius--3;
        }

        &-6 {
            border-radius: $dimensions-border-radius--6;
        }

        &-16 {
            border-radius: $dimensions-border-radius--16;
        }

        &-32 {
            border-radius: $dimensions-border-radius--32;
        }
    
        &-50p {
            border-radius: 50%;
        }
    }
}

.rad-b-r {
    @include responsive() {
        &-none {
            border-bottom-right-radius: 0;
        }

        &-3 {
            border-bottom-right-radius: $dimensions-border-radius--3;
        }

        &-6 {
            border-bottom-right-radius: $dimensions-border-radius--6;
        }

        &-16 {
            border-bottom-right-radius: $dimensions-border-radius--16;
        }

        &-32 {
            border-bottom-right-radius: $dimensions-border-radius--32;
        }
    }
}

.rad-b-l {
    @include responsive() {
        &-none {
            border-bottom-left-radius: 0;
        }

        &-3 {
            border-bottom-left-radius: $dimensions-border-radius--3;
        }

        &-6 {
            border-bottom-left-radius: $dimensions-border-radius--6;
        }

        &-16 {
            border-bottom-left-radius: $dimensions-border-radius--16;
        }

        &-32 {
            border-bottom-left-radius: $dimensions-border-radius--32;
        }
    }
}

.rad-t-l {
    @include responsive() {
        &-none {
            border-top-left-radius: 0;
        }

        &-3 {
            border-top-left-radius: $dimensions-border-radius--3;
        }

        &-6 {
            border-top-left-radius: $dimensions-border-radius--6;
        }

        &-16 {
            border-top-left-radius: $dimensions-border-radius--16;
        }

        &-32 {
            border-top-left-radius: $dimensions-border-radius--32;
        }
    }
}

.rad-t-r {
    @include responsive() {
        &-none {
            border-top-right-radius: 0;
        }

        &-3 {
            border-top-right-radius: $dimensions-border-radius--3;
        }

        &-6 {
            border-top-right-radius: $dimensions-border-radius--6;
        }

        &-16 {
            border-top-right-radius: $dimensions-border-radius--16;
        }

        &-32 {
            border-top-right-radius: $dimensions-border-radius--32;
        }
    }
}

.rad-r {
    @include responsive() {
        &-none {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
        
        &-3 {
            border-bottom-right-radius: $dimensions-border-radius--3;
            border-top-right-radius: $dimensions-border-radius--3;
        }
        
        &-6 {
            border-bottom-right-radius: $dimensions-border-radius--6;
            border-top-right-radius: $dimensions-border-radius--6;
        }
        
        &-16 {
            border-bottom-right-radius: $dimensions-border-radius--16;
            border-top-right-radius: $dimensions-border-radius--16;
        }
        
        &-32 {
            border-bottom-right-radius: $dimensions-border-radius--32;
            border-top-right-radius: $dimensions-border-radius--32;
        }
    }
}

.rad-l {
    @include responsive() {
        &-none {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
        
        &-3 {
            border-bottom-left-radius: $dimensions-border-radius--3;
            border-top-left-radius: $dimensions-border-radius--3;
        }
        
        &-6 {
            border-bottom-left-radius: $dimensions-border-radius--6;
            border-top-left-radius: $dimensions-border-radius--6;
        }
        
        &-16 {
            border-bottom-left-radius: $dimensions-border-radius--16;
            border-top-left-radius: $dimensions-border-radius--16;
        }
        
        &-32 {
            border-bottom-left-radius: $dimensions-border-radius--32;
            border-top-left-radius: $dimensions-border-radius--32;
        }
    }
}
