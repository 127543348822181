.complex {
    &-pricing {
        &-table {
            @include media('<=me') {
                tbody {
                    display: block;
                    width: 100%;
                }
            }

            tr {
                > td {
                    vertical-align: top;
                }

                &.is--th {
                    @include media('<=me') {
                        display: flex;
                        width: 100%;

                        > td {
                            width: 100% !important;

                            &:empty {
                                display: none;
                            }
                        }
                    }

                    @include media('<=sm') {
                        flex-wrap: wrap;
                    }

                    @include media('>me') {
                        > td {
                            &:not(:last-of-type) {
                                border-right: solid $dimensions-border--small $color-border--grey;
                            }
                        }
                    }
                }
            }
        }

        &-th {
            @include media('<=sm') {
                box-shadow: $shadow-color-strong;
            }
        }

        &-function {
            @include media('<=sm') {
                display: flex;
                flex-wrap: wrap;
            }

            td {
                @include media('<=sm') {
                    display: block;
                    height: 100%;
                    width: 33.3333% !important;

                    &:first-of-type {
                        width: 100% !important;
                    }
                }

                @include media('>sm') {
                    border-bottom: solid $dimensions-border--small $color-border--grey;

                    &:not(:last-of-type) {
                        border-right: solid $dimensions-border--small $color-border--grey;
                    }
                }
            }
        }

        &-category {
            @include media('<=sm') {
                &:empty {
                    display: none;
                }
            }

            @include media('>sm') {
                border-bottom: solid $dimensions-border--default $color-border--grey;

                &:not(:last-of-type) {
                    border-right: solid $dimensions-border--small $color-border--grey;
                }
            }
        }

        &-special {
            transform: translate(-$margin--20, -50%);
            @include media('>sm') {
                transform: inherit;
            }

            @include media('>me') {
                @include position(absolute, $top: 0, $left: 0);
                transform: translate($margin--20, -75%);
            }

            @include media('<=sm') {
                @include position(absolute, $top: 0, $right: 0);
            }
        }

        &-mobile {
            &-cat {
                @include media('>sm') {
                    display: none;
                }
            }
        }
    }
}
