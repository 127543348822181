$icons: (
    music: '\e800', /* '' */
    lemon: '\e801', /* '' */
    down-circled2: '\e802', /* '' */
    up-circled2: '\e803', /* '' */
    down-dir: '\e804', /* '' */
    up-dir: '\e805', /* '' */
    left-dir: '\e806', /* '' */
    right-dir: '\e807', /* '' */
    down-open: '\e808', /* '' */
    left-open: '\e809', /* '' */
    right-open: '\e80a', /* '' */
    up-open: '\e80b', /* '' */
    down-big: '\e80c', /* '' */
    left-big: '\e80d', /* '' */
    right-big: '\e80e', /* '' */
    up-big: '\e80f', /* '' */
    right-hand: '\e810', /* '' */
    left-hand: '\e811', /* '' */
    up-hand: '\e812', /* '' */
    down-hand: '\e813', /* '' */
    cw: '\e814', /* '' */
    ccw: '\e815', /* '' */
    arrows-cw: '\e816', /* '' */
    shuffle: '\e817', /* '' */
    play: '\e818', /* '' */
    play-circled2: '\e819', /* '' */
    stop: '\e81a', /* '' */
    pause: '\e81b', /* '' */
    to-end: '\e81c', /* '' */
    to-end-alt: '\e81d', /* '' */
    to-start: '\e81e', /* '' */
    to-start-alt: '\e81f', /* '' */
    fast-fw: '\e820', /* '' */
    fast-bw: '\e821', /* '' */
    eject: '\e822', /* '' */
    target: '\e823', /* '' */
    signal: '\e824', /* '' */
    award: '\e825', /* '' */
    inbox: '\e826', /* '' */
    globe: '\e827', /* '' */
    cloud: '\e828', /* '' */
    flash: '\e829', /* '' */
    umbrella: '\e82a', /* '' */
    flight: '\e82b', /* '' */
    leaf: '\e82c', /* '' */
    font: '\e82d', /* '' */
    bold: '\e82e', /* '' */
    italic: '\e82f', /* '' */
    text-height: '\e830', /* '' */
    text-width: '\e831', /* '' */
    align-left: '\e832', /* '' */
    align-center: '\e833', /* '' */
    align-right: '\e834', /* '' */
    align-justify: '\e835', /* '' */
    list: '\e836', /* '' */
    indent-left: '\e837', /* '' */
    indent-right: '\e838', /* '' */
    scissors: '\e839', /* '' */
    briefcase: '\e83a', /* '' */
    off: '\e83b', /* '' */
    road: '\e83c', /* '' */
    list-alt: '\e83d', /* '' */
    qrcode: '\e83e', /* '' */
    barcode: '\e83f', /* '' */
    book: '\e840', /* '' */
    adjust: '\e841', /* '' */
    tint: '\e842', /* '' */
    check: '\e843', /* '' */
    asterisk: '\e844', /* '' */
    gift: '\e845', /* '' */
    fire: '\e846', /* '' */
    magnet: '\e847', /* '' */
    chart-bar: '\e848', /* '' */
    credit-card: '\e849', /* '' */
    floppy: '\e84a', /* '' */
    megaphone: '\e84b', /* '' */
    key: '\e84c', /* '' */
    truck: '\e84d', /* '' */
    hammer: '\e84e', /* '' */
    search: '\e84f', /* '' */
    mail: '\e850', /* '' */
    heart: '\e851', /* '' */
    heart-empty: '\e852', /* '' */
    star: '\e853', /* '' */
    star-empty: '\e854', /* '' */
    star-half: '\e855', /* '' */
    user: '\e856', /* '' */
    users: '\e857', /* '' */
    video: '\e858', /* '' */
    videocam: '\e859', /* '' */
    picture: '\e85a', /* '' */
    camera: '\e85b', /* '' */
    camera-alt: '\e85c', /* '' */
    th-large: '\e85d', /* '' */
    th: '\e85e', /* '' */
    th-list: '\e85f', /* '' */
    ok: '\e860', /* '' */
    ok-circled: '\e861', /* '' */
    ok-circled2: '\e862', /* '' */
    cancel: '\e863', /* '' */
    cancel-circled: '\e864', /* '' */
    cancel-circled2: '\e865', /* '' */
    plus: '\e866', /* '' */
    plus-circled: '\e867', /* '' */
    minus: '\e868', /* '' */
    minus-circled: '\e869', /* '' */
    help-circled: '\e86a', /* '' */
    info-circled: '\e86b', /* '' */
    home: '\e86c', /* '' */
    link: '\e86d', /* '' */
    attach: '\e86e', /* '' */
    lock: '\e86f', /* '' */
    lock-open: '\e870', /* '' */
    pin: '\e871', /* '' */
    eye: '\e872', /* '' */
    eye-off: '\e873', /* '' */
    tag: '\e874', /* '' */
    tags: '\e875', /* '' */
    bookmark: '\e876', /* '' */
    flag: '\e877', /* '' */
    thumbs-up: '\e878', /* '' */
    thumbs-down: '\e879', /* '' */
    download: '\e87a', /* '' */
    upload: '\e87b', /* '' */
    forward: '\e87c', /* '' */
    export: '\e87d', /* '' */
    pencil: '\e87e', /* '' */
    edit: '\e87f', /* '' */
    print: '\e880', /* '' */
    retweet: '\e881', /* '' */
    comment: '\e882', /* '' */
    chat: '\e883', /* '' */
    bell: '\e884', /* '' */
    attention: '\e885', /* '' */
    attention-circled: '\e886', /* '' */
    location: '\e887', /* '' */
    trash-empty: '\e888', /* '' */
    doc: '\e889', /* '' */
    folder: '\e88a', /* '' */
    folder-open: '\e88b', /* '' */
    phone: '\e88c', /* '' */
    cog: '\e88d', /* '' */
    cog-alt: '\e88e', /* '' */
    wrench: '\e88f', /* '' */
    basket: '\e890', /* '' */
    calendar: '\e891', /* '' */
    login: '\e892', /* '' */
    logout: '\e893', /* '' */
    volume-off: '\e894', /* '' */
    volume-down: '\e895', /* '' */
    volume-up: '\e896', /* '' */
    headphones: '\e897', /* '' */
    clock: '\e898', /* '' */
    block: '\e899', /* '' */
    resize-full: '\e89a', /* '' */
    resize-small: '\e89b', /* '' */
    resize-vertical: '\e89c', /* '' */
    resize-horizontal: '\e89d', /* '' */
    zoom-in: '\e89e', /* '' */
    zoom-out: '\e89f', /* '' */
    glass: '\e8a0', /* '' */
    move: '\f047', /* '' */
    link-ext: '\f08e', /* '' */
    check-empty: '\f096', /* '' */
    bookmark-empty: '\f097', /* '' */
    phone-squared: '\f098', /* '' */
    twitter: '\f099', /* '' */
    facebook: '\f09a', /* '' */
    github-circled: '\f09b', /* '' */
    rss: '\f09e', /* '' */
    hdd: '\f0a0', /* '' */
    certificate: '\f0a3', /* '' */
    left-circled: '\f0a8', /* '' */
    right-circled: '\f0a9', /* '' */
    up-circled: '\f0aa', /* '' */
    down-circled: '\f0ab', /* '' */
    tasks: '\f0ae', /* '' */
    filter: '\f0b0', /* '' */
    resize-full-alt: '\f0b2', /* '' */
    beaker: '\f0c3', /* '' */
    docs: '\f0c5', /* '' */
    blank: '\f0c8', /* '' */
    menu: '\f0c9', /* '' */
    list-bullet: '\f0ca', /* '' */
    list-numbered: '\f0cb', /* '' */
    strike: '\f0cc', /* '' */
    underline: '\f0cd', /* '' */
    table: '\f0ce', /* '' */
    magic: '\f0d0', /* '' */
    pinterest-circled: '\f0d2', /* '' */
    pinterest-squared: '\f0d3', /* '' */
    gplus-squared: '\f0d4', /* '' */
    gplus: '\f0d5', /* '' */
    money: '\f0d6', /* '' */
    columns: '\f0db', /* '' */
    sort: '\f0dc', /* '' */
    sort-down: '\f0dd', /* '' */
    sort-up: '\f0de', /* '' */
    mail-alt: '\f0e0', /* '' */
    linkedin: '\f0e1', /* '' */
    gauge: '\f0e4', /* '' */
    comment-empty: '\f0e5', /* '' */
    chat-empty: '\f0e6', /* '' */
    sitemap: '\f0e8', /* '' */
    paste: '\f0ea', /* '' */
    lightbulb: '\f0eb', /* '' */
    exchange: '\f0ec', /* '' */
    download-cloud: '\f0ed', /* '' */
    upload-cloud: '\f0ee', /* '' */
    user-md: '\f0f0', /* '' */
    stethoscope: '\f0f1', /* '' */
    suitcase: '\f0f2', /* '' */
    bell-alt: '\f0f3', /* '' */
    coffee: '\f0f4', /* '' */
    food: '\f0f5', /* '' */
    doc-text: '\f0f6', /* '' */
    building: '\f0f7', /* '' */
    hospital: '\f0f8', /* '' */
    ambulance: '\f0f9', /* '' */
    medkit: '\f0fa', /* '' */
    fighter-jet: '\f0fb', /* '' */
    beer: '\f0fc', /* '' */
    h-sigh: '\f0fd', /* '' */
    plus-squared: '\f0fe', /* '' */
    angle-double-left: '\f100', /* '' */
    angle-double-right: '\f101', /* '' */
    angle-double-up: '\f102', /* '' */
    angle-double-down: '\f103', /* '' */
    angle-left: '\f104', /* '' */
    angle-right: '\f105', /* '' */
    angle-up: '\f106', /* '' */
    angle-down: '\f107', /* '' */
    desktop: '\f108', /* '' */
    laptop: '\f109', /* '' */
    tablet: '\f10a', /* '' */
    mobile: '\f10b', /* '' */
    circle-empty: '\f10c', /* '' */
    quote-left: '\f10d', /* '' */
    quote-right: '\f10e', /* '' */
    spinner: '\f110', /* '' */
    circle: '\f111', /* '' */
    reply: '\f112', /* '' */
    github: '\f113', /* '' */
    folder-empty: '\f114', /* '' */
    folder-open-empty: '\f115', /* '' */
    smile: '\f118', /* '' */
    frown: '\f119', /* '' */
    meh: '\f11a', /* '' */
    gamepad: '\f11b', /* '' */
    keyboard: '\f11c', /* '' */
    flag-empty: '\f11d', /* '' */
    flag-checkered: '\f11e', /* '' */
    terminal: '\f120', /* '' */
    code: '\f121', /* '' */
    reply-all: '\f122', /* '' */
    star-half-alt: '\f123', /* '' */
    direction: '\f124', /* '' */
    crop: '\f125', /* '' */
    fork: '\f126', /* '' */
    unlink: '\f127', /* '' */
    help: '\f128', /* '' */
    info: '\f129', /* '' */
    attention-alt: '\f12a', /* '' */
    superscript: '\f12b', /* '' */
    subscript: '\f12c', /* '' */
    eraser: '\f12d', /* '' */
    puzzle: '\f12e', /* '' */
    mic: '\f130', /* '' */
    mute: '\f131', /* '' */
    shield: '\f132', /* '' */
    calendar-empty: '\f133', /* '' */
    extinguisher: '\f134', /* '' */
    rocket: '\f135', /* '' */
    maxcdn: '\f136', /* '' */
    angle-circled-left: '\f137', /* '' */
    angle-circled-right: '\f138', /* '' */
    angle-circled-up: '\f139', /* '' */
    angle-circled-down: '\f13a', /* '' */
    html5: '\f13b', /* '' */
    css3: '\f13c', /* '' */
    anchor: '\f13d', /* '' */
    lock-open-alt: '\f13e', /* '' */
    bullseye: '\f140', /* '' */
    ellipsis: '\f141', /* '' */
    ellipsis-vert: '\f142', /* '' */
    rss-squared: '\f143', /* '' */
    play-circled: '\f144', /* '' */
    ticket: '\f145', /* '' */
    minus-squared: '\f146', /* '' */
    minus-squared-alt: '\f147', /* '' */
    level-up: '\f148', /* '' */
    level-down: '\f149', /* '' */
    ok-squared: '\f14a', /* '' */
    pencil-squared: '\f14b', /* '' */
    link-ext-alt: '\f14c', /* '' */
    export-alt: '\f14d', /* '' */
    compass: '\f14e', /* '' */
    expand: '\f150', /* '' */
    collapse: '\f151', /* '' */
    expand-right: '\f152', /* '' */
    euro: '\f153', /* '' */
    pound: '\f154', /* '' */
    dollar: '\f155', /* '' */
    rupee: '\f156', /* '' */
    yen: '\f157', /* '' */
    rouble: '\f158', /* '' */
    won: '\f159', /* '' */
    bitcoin: '\f15a', /* '' */
    doc-inv: '\f15b', /* '' */
    doc-text-inv: '\f15c', /* '' */
    sort-name-up: '\f15d', /* '' */
    sort-name-down: '\f15e', /* '' */
    sort-alt-up: '\f160', /* '' */
    sort-alt-down: '\f161', /* '' */
    sort-number-up: '\f162', /* '' */
    sort-number-down: '\f163', /* '' */
    thumbs-up-alt: '\f164', /* '' */
    thumbs-down-alt: '\f165', /* '' */
    youtube-squared: '\f166', /* '' */
    youtube: '\f167', /* '' */
    xing: '\f168', /* '' */
    xing-squared: '\f169', /* '' */
    youtube-play: '\f16a', /* '' */
    dropbox: '\f16b', /* '' */
    stackoverflow: '\f16c', /* '' */
    instagram: '\f16d', /* '' */
    flickr: '\f16e', /* '' */
    adn: '\f170', /* '' */
    bitbucket: '\f171', /* '' */
    bitbucket-squared: '\f172', /* '' */
    tumblr: '\f173', /* '' */
    tumblr-squared: '\f174', /* '' */
    down: '\f175', /* '' */
    up: '\f176', /* '' */
    left: '\f177', /* '' */
    right: '\f178', /* '' */
    apple: '\f179', /* '' */
    windows: '\f17a', /* '' */
    android: '\f17b', /* '' */
    linux: '\f17c', /* '' */
    dribbble: '\f17d', /* '' */
    skype: '\f17e', /* '' */
    foursquare: '\f180', /* '' */
    trello: '\f181', /* '' */
    female: '\f182', /* '' */
    male: '\f183', /* '' */
    gittip: '\f184', /* '' */
    sun: '\f185', /* '' */
    moon: '\f186', /* '' */
    box: '\f187', /* '' */
    bug: '\f188', /* '' */
    vkontakte: '\f189', /* '' */
    weibo: '\f18a', /* '' */
    renren: '\f18b', /* '' */
    pagelines: '\f18c', /* '' */
    stackexchange: '\f18d', /* '' */
    right-circled2: '\f18e', /* '' */
    left-circled2: '\f190', /* '' */
    collapse-left: '\f191', /* '' */
    dot-circled: '\f192', /* '' */
    wheelchair: '\f193', /* '' */
    vimeo-squared: '\f194', /* '' */
    try: '\f195', /* '' */
    plus-squared-alt: '\f196', /* '' */
    space-shuttle: '\f197', /* '' */
    slack: '\f198', /* '' */
    mail-squared: '\f199', /* '' */
    wordpress: '\f19a', /* '' */
    openid: '\f19b', /* '' */
    bank: '\f19c', /* '' */
    graduation-cap: '\f19d', /* '' */
    yahoo: '\f19e', /* '' */
    google: '\f1a0', /* '' */
    reddit: '\f1a1', /* '' */
    reddit-squared: '\f1a2', /* '' */
    stumbleupon-circled: '\f1a3', /* '' */
    stumbleupon: '\f1a4', /* '' */
    delicious: '\f1a5', /* '' */
    digg: '\f1a6', /* '' */
    pied-piper-squared: '\f1a7', /* '' */
    pied-piper-alt: '\f1a8', /* '' */
    drupal: '\f1a9', /* '' */
    joomla: '\f1aa', /* '' */
    language: '\f1ab', /* '' */
    fax: '\f1ac', /* '' */
    building-filled: '\f1ad', /* '' */
    child: '\f1ae', /* '' */
    paw: '\f1b0', /* '' */
    spoon: '\f1b1', /* '' */
    cube: '\f1b2', /* '' */
    cubes: '\f1b3', /* '' */
    behance: '\f1b4', /* '' */
    behance-squared: '\f1b5', /* '' */
    steam: '\f1b6', /* '' */
    steam-squared: '\f1b7', /* '' */
    recycle: '\f1b8', /* '' */
    cab: '\f1b9', /* '' */
    taxi: '\f1ba', /* '' */
    tree: '\f1bb', /* '' */
    spotify: '\f1bc', /* '' */
    deviantart: '\f1bd', /* '' */
    soundcloud: '\f1be', /* '' */
    database: '\f1c0', /* '' */
    file-pdf: '\f1c1', /* '' */
    file-word: '\f1c2', /* '' */
    file-excel: '\f1c3', /* '' */
    file-powerpoint: '\f1c4', /* '' */
    file-image: '\f1c5', /* '' */
    file-archive: '\f1c6', /* '' */
    file-audio: '\f1c7', /* '' */
    file-video: '\f1c8', /* '' */
    file-code: '\f1c9', /* '' */
    vine: '\f1ca', /* '' */
    codeopen: '\f1cb', /* '' */
    jsfiddle: '\f1cc', /* '' */
    lifebuoy: '\f1cd', /* '' */
    circle-notch: '\f1ce', /* '' */
    rebel: '\f1d0', /* '' */
    empire: '\f1d1', /* '' */
    git-squared: '\f1d2', /* '' */
    git: '\f1d3', /* '' */
    hacker-news: '\f1d4', /* '' */
    tencent-weibo: '\f1d5', /* '' */
    qq: '\f1d6', /* '' */
    wechat: '\f1d7', /* '' */
    paper-plane: '\f1d8', /* '' */
    paper-plane-empty: '\f1d9', /* '' */
    history: '\f1da', /* '' */
    circle-thin: '\f1db', /* '' */
    header: '\f1dc', /* '' */
    paragraph: '\f1dd', /* '' */
    sliders: '\f1de', /* '' */
    share: '\f1e0', /* '' */
    share-squared: '\f1e1', /* '' */
    bomb: '\f1e2', /* '' */
    soccer-ball: '\f1e3', /* '' */
    tty: '\f1e4', /* '' */
    binoculars: '\f1e5', /* '' */
    plug: '\f1e6', /* '' */
    slideshare: '\f1e7', /* '' */
    twitch: '\f1e8', /* '' */
    yelp: '\f1e9', /* '' */
    newspaper: '\f1ea', /* '' */
    wifi: '\f1eb', /* '' */
    calc: '\f1ec', /* '' */
    paypal: '\f1ed', /* '' */
    gwallet: '\f1ee', /* '' */
    cc-visa: '\f1f0', /* '' */
    cc-mastercard: '\f1f1', /* '' */
    cc-discover: '\f1f2', /* '' */
    cc-amex: '\f1f3', /* '' */
    cc-paypal: '\f1f4', /* '' */
    cc-stripe: '\f1f5', /* '' */
    bell-off: '\f1f6', /* '' */
    bell-off-empty: '\f1f7', /* '' */
    trash: '\f1f8', /* '' */
    copyright: '\f1f9', /* '' */
    at: '\f1fa', /* '' */
    eyedropper: '\f1fb', /* '' */
    brush: '\f1fc', /* '' */
    birthday: '\f1fd', /* '' */
    chart-area: '\f1fe', /* '' */
    chart-pie: '\f200', /* '' */
    chart-line: '\f201', /* '' */
    lastfm: '\f202', /* '' */
    lastfm-squared: '\f203', /* '' */
    toggle-off: '\f204', /* '' */
    toggle-on: '\f205', /* '' */
    bicycle: '\f206', /* '' */
    bus: '\f207', /* '' */
    ioxhost: '\f208', /* '' */
    angellist: '\f209', /* '' */
    cc: '\f20a', /* '' */
    shekel: '\f20b', /* '' */
    meanpath: '\f20c', /* '' */
    buysellads: '\f20d', /* '' */
    connectdevelop: '\f20e', /* '' */
    dashcube: '\f210', /* '' */
    forumbee: '\f211', /* '' */
    leanpub: '\f212', /* '' */
    sellsy: '\f213', /* '' */
    shirtsinbulk: '\f214', /* '' */
    simplybuilt: '\f215', /* '' */
    skyatlas: '\f216', /* '' */
    cart-plus: '\f217', /* '' */
    cart-arrow-down: '\f218', /* '' */
    diamond: '\f219', /* '' */
    ship: '\f21a', /* '' */
    user-secret: '\f21b', /* '' */
    motorcycle: '\f21c', /* '' */
    street-view: '\f21d', /* '' */
    heartbeat: '\f21e', /* '' */
    venus: '\f221', /* '' */
    mars: '\f222', /* '' */
    mercury: '\f223', /* '' */
    transgender: '\f224', /* '' */
    transgender-alt: '\f225', /* '' */
    venus-double: '\f226', /* '' */
    mars-double: '\f227', /* '' */
    venus-mars: '\f228', /* '' */
    mars-stroke: '\f229', /* '' */
    mars-stroke-v: '\f22a', /* '' */
    mars-stroke-h: '\f22b', /* '' */
    neuter: '\f22c', /* '' */
    genderless: '\f22d', /* '' */
    facebook-official: '\f230', /* '' */
    pinterest: '\f231', /* '' */
    whatsapp: '\f232', /* '' */
    server: '\f233', /* '' */
    user-plus: '\f234', /* '' */
    user-times: '\f235', /* '' */
    bed: '\f236', /* '' */
    viacoin: '\f237', /* '' */
    train: '\f238', /* '' */
    subway: '\f239', /* '' */
    medium: '\f23a', /* '' */
    y-combinator: '\f23b', /* '' */
    optin-monster: '\f23c', /* '' */
    opencart: '\f23d', /* '' */
    expeditedssl: '\f23e', /* '' */
    battery-4: '\f240', /* '' */
    battery-3: '\f241', /* '' */
    battery-2: '\f242', /* '' */
    battery-1: '\f243', /* '' */
    battery-0: '\f244', /* '' */
    mouse-pointer: '\f245', /* '' */
    i-cursor: '\f246', /* '' */
    object-group: '\f247', /* '' */
    object-ungroup: '\f248', /* '' */
    sticky-note: '\f249', /* '' */
    sticky-note-o: '\f24a', /* '' */
    cc-jcb: '\f24b', /* '' */
    cc-diners-club: '\f24c', /* '' */
    clone: '\f24d', /* '' */
    balance-scale: '\f24e', /* '' */
    hourglass-o: '\f250', /* '' */
    hourglass-1: '\f251', /* '' */
    hourglass-2: '\f252', /* '' */
    hourglass-3: '\f253', /* '' */
    hourglass: '\f254', /* '' */
    hand-grab-o: '\f255', /* '' */
    hand-paper-o: '\f256', /* '' */
    hand-scissors-o: '\f257', /* '' */
    hand-lizard-o: '\f258', /* '' */
    hand-spock-o: '\f259', /* '' */
    hand-pointer-o: '\f25a', /* '' */
    hand-peace-o: '\f25b', /* '' */
    trademark: '\f25c', /* '' */
    registered: '\f25d', /* '' */
    creative-commons: '\f25e', /* '' */
    gg: '\f260', /* '' */
    gg-circle: '\f261', /* '' */
    tripadvisor: '\f262', /* '' */
    odnoklassniki: '\f263', /* '' */
    odnoklassniki-square: '\f264', /* '' */
    get-pocket: '\f265', /* '' */
    wikipedia-w: '\f266', /* '' */
    safari: '\f267', /* '' */
    chrome: '\f268', /* '' */
    firefox: '\f269', /* '' */
    opera: '\f26a', /* '' */
    internet-explorer: '\f26b', /* '' */
    television: '\f26c', /* '' */
    contao: '\f26d', /* '' */
    amazon: '\f270', /* '' */
    calendar-plus-o: '\f271', /* '' */
    calendar-minus-o: '\f272', /* '' */
    calendar-times-o: '\f273', /* '' */
    calendar-check-o: '\f274', /* '' */
    industry: '\f275', /* '' */
    map-pin: '\f276', /* '' */
    map-signs: '\f277', /* '' */
    map-o: '\f278', /* '' */
    map: '\f279', /* '' */
    commenting: '\f27a', /* '' */
    commenting-o: '\f27b', /* '' */
    houzz: '\f27c', /* '' */
    vimeo: '\f27d', /* '' */
    black-tie: '\f27e', /* '' */
    fonticons: '\f280', /* '' */
    reddit-alien: '\f281', /* '' */
    edge: '\f282', /* '' */
    credit-card-alt: '\f283', /* '' */
    codiepie: '\f284', /* '' */
    modx: '\f285', /* '' */
    fort-awesome: '\f286', /* '' */
    usb: '\f287', /* '' */
    product-hunt: '\f288', /* '' */
    mixcloud: '\f289', /* '' */
    scribd: '\f28a', /* '' */
    pause-circle: '\f28b', /* '' */
    pause-circle-o: '\f28c', /* '' */
    stop-circle: '\f28d', /* '' */
    stop-circle-o: '\f28e', /* '' */
    shopping-bag: '\f290', /* '' */
    shopping-basket: '\f291', /* '' */
    hashtag: '\f292', /* '' */
    bluetooth: '\f293', /* '' */
    bluetooth-b: '\f294', /* '' */
    percent: '\f295', /* '' */
    gitlab: '\f296', /* '' */
    wpbeginner: '\f297', /* '' */
    wpforms: '\f298', /* '' */
    envira: '\f299', /* '' */
    universal-access: '\f29a', /* '' */
    wheelchair-alt: '\f29b', /* '' */
    question-circle-o: '\f29c', /* '' */
    blind: '\f29d', /* '' */
    audio-description: '\f29e', /* '' */
    volume-control-phone: '\f2a0', /* '' */
    braille: '\f2a1', /* '' */
    assistive-listening-systems: '\f2a2', /* '' */
    american-sign-language-interpreting: '\f2a3', /* '' */
    asl-interpreting: '\f2a4', /* '' */
    glide: '\f2a5', /* '' */
    glide-g: '\f2a6', /* '' */
    sign-language: '\f2a7', /* '' */
    low-vision: '\f2a8', /* '' */
    viadeo: '\f2a9', /* '' */
    viadeo-square: '\f2aa', /* '' */
    snapchat: '\f2ab', /* '' */
    snapchat-ghost: '\f2ac', /* '' */
    snapchat-square: '\f2ad', /* '' */
    pied-piper: '\f2ae', /* '' */
    first-order: '\f2b0', /* '' */
    yoast: '\f2b1', /* '' */
    themeisle: '\f2b2', /* '' */
    google-plus-circle: '\f2b3', /* '' */
    font-awesome: '\f2b4', /* '' */
    handshake-o: '\f2b5', /* '' */
    envelope-open: '\f2b6', /* '' */
    envelope-open-o: '\f2b7', /* '' */
    linode: '\f2b8', /* '' */
    address-book: '\f2b9', /* '' */
    address-book-o: '\f2ba', /* '' */
    address-card: '\f2bb', /* '' */
    address-card-o: '\f2bc', /* '' */
    user-circle: '\f2bd', /* '' */
    user-circle-o: '\f2be', /* '' */
    user-o: '\f2c0', /* '' */
    id-badge: '\f2c1', /* '' */
    id-card: '\f2c2', /* '' */
    id-card-o: '\f2c3', /* '' */
    quora: '\f2c4', /* '' */
    free-code-camp: '\f2c5', /* '' */
    telegram: '\f2c6', /* '' */
    thermometer: '\f2c7', /* '' */
    thermometer-3: '\f2c8', /* '' */
    thermometer-2: '\f2c9', /* '' */
    thermometer-quarter: '\f2ca', /* '' */
    thermometer-0: '\f2cb', /* '' */
    shower: '\f2cc', /* '' */
    bath: '\f2cd', /* '' */
    podcast: '\f2ce', /* '' */
    window-maximize: '\f2d0', /* '' */
    window-minimize: '\f2d1', /* '' */
    window-restore: '\f2d2', /* '' */
    window-close: '\f2d3', /* '' */
    window-close-o: '\f2d4', /* '' */
    bandcamp: '\f2d5', /* '' */
    grav: '\f2d6', /* '' */
    etsy: '\f2d7', /* '' */
    imdb: '\f2d8', /* '' */
    ravelry: '\f2d9', /* '' */
    eercast: '\f2da', /* '' */
    microchip: '\f2db', /* '' */
    snowflake-o: '\f2dc', /* '' */
    superpowers: '\f2dd', /* '' */
    wpexplorer: '\f2de', /* '' */
    meetup: '\f2e0', /* '' */
    github-squared: '\f300', /* '' */
    twitter-squared: '\f304', /* '' */
    facebook-squared: '\f308', /* '' */
    linkedin-squared: '\f30c' /* '' */
);

@mixin icons($codes) {
    @each $code, $glyph in $codes {
        .ico-#{$code} {
            @include icon(before, #{$code});
        }

        .ico-a-#{$code} {
            @include icon(after, #{$code});
        }
    }
    @content;
}

@include icons($icons);

[class*='ico-'] {
    &:after,
    &:before {
        display: inline-block;
        font-family: $font-family--icons;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        position: relative;
        speak: none;
        text-align: center;
        text-decoration: none;
        text-transform: none;
        width: auto;
    }
}
