.complex {
    &-datepicker {
        height: 48px !important;
        padding: 0 !important;
        
        .date {
            &-picker {
                &-input {
                    height: 48px !important;
                }
            }
        }
    }
}
