.pos {
    @include responsive() {
        &-relative {
            position: relative;
        }

        &-abs {
            position: absolute;

            &-topleft {
                left: 0;
                top: 0;
            }
    
            &-topright {
                right: 0;
                top: 0;
            }
    
            &-bottomleft {
                bottom: 0;
                left: 0;
            }
        }
        
        &-fix {
            position: fixed;
            
            &-topleft {
                left: 0;
                top: 0;
            }
        }
    }
}
