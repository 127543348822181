.w {
    @include responsive() {
        &-inherit {
            width: inherit;
        }

        &-26px {
            width: 1.625rem;
        }

        &-44px {
            height: 2.75rem;
        }
        
        &-100 {
            width: 100%;
        }

        &-45 {
            width: 45%;
        }
    
        &-3rem {
            width: 3rem;
        }

        &-4rem {
            width: 4rem;
        }

        &-5rem {
            width: 5rem;
        }
    
        &-6rem {
            width: 6rem;
        }

        &-8rem {
            width: 8rem;
        }

        &-10rem {
            width: 10rem;
        }

        &-12rem {
            width: 12rem;
        }

        &-15rem {
            width: 15rem;
        }

        &-20rem {
            width: 20rem;
        }

        &-2_3 {
            width: 66.6666%;
        }

        &-1_3 {
            width: 33.3333%;
        }

        &-1_4 {
            width: 25%;
        }
    
        &-1_5 {
            width: 20%;
        }
    
        &-4_5 {
            width: 80%;
        }

        &-3_4 {
            width: 75%;
        }

        &-1_2 {
            width: 50%;
        }

        &-1_6 {
            width: 16.6666%;
        }
        
        &-5_6 {
            width: 83.3333%;
        }

        &-1_12 {
            width: 8.3333%;
        }
    }
}
