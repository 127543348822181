@mixin do-paddings($direction: '', $attr: '', $pseudo: '', $attr-2: '') {
    @if $direction != '' {
        $direction: '-' + $direction;
    }

    @if $attr != '' {
        $attr: '-' + $attr;
    }

    @each $name, $margin in $margins {
        &#{$direction}-#{$name} {
            @if $pseudo == '' {
                padding#{$attr}: $margin;
                @if $attr-2 != '' {
                    padding-#{$attr-2}: $margin;
                }
            } @else {
                &:#{$pseudo} {
                    padding#{$attr}: $margin;
                    @if $attr-2 != '' {
                        padding-#{$attr-2}: $margin;
                    }
                }
            }
        }
    }
}

.p {
    @include responsive() {
        @include do-paddings();
        @include do-paddings('t', 'top');
        @include do-paddings('l', 'left');
        @include do-paddings('b', 'bottom');
        @include do-paddings('r', 'right');
        @include do-paddings('tb', 'top', '', 'bottom');
        @include do-paddings('lr', 'left', '', 'right');
    }
}

.before {
    &-p {
        @include do-paddings('r', 'right', 'before');
        @include do-paddings('l', 'left', 'before');
    }
}

.after {
    &-p {
        @include do-paddings('l', 'left', 'after');
    }
}
