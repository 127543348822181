@mixin do-colors($pseudo: '', $pseudosec: '') {
    @each $name, $color in $colors {
        @if $pseudo == '*' {
            @if $pseudosec == '' {
                &-#{$name} {
                    &,
                    * {
                        color: $color;
                    }
                }
            } @else {
                &-#{$name} {
                    &:#{$pseudosec} {
                        &,
                        * {
                            color: $color;
                        }
                    }
                }
            }
        } @else {
            &-#{$name} {
                @if $pseudo == '' {
                    color: $color;
                } @else {
                    &:#{$pseudo} {
                        color: $color;
                    }
                }
            }

            &-bg-#{$name} {
                @if $pseudo == '' {
                    background-color: $color;
                } @else {
                    &:#{$pseudo} {
                        background-color: $color;
                    }
                }
            }
        }
    }
}

@mixin do-grad-colors($pseudo: '') {
    @each $name, $color in $colors {
        &-#{$name} {
            @if $pseudo == '' {
                background-image: linear-gradient(90deg, $color, $color);
            } @else {
                &:#{$pseudo} {
                    background-image: linear-gradient(90deg, $color, $color);
                }
            }
        }
    }

    @each $name, $color in $colors-grad {
        &-#{$name} {
            @if $pseudo == '' {
                background-image: $color;
            } @else {
                &:#{$pseudo} {
                    background-image: $color;
                }
            }
        }
    }
}

.c {
    @include responsive() {
        @include do-colors();
    }

    &-grad {
        @include do-grad-colors();
    }
}

.child-c {
    @include do-colors('*');
}

.hover-child-c {
    @include do-colors('*', 'hover');
}

.after-c {
    @include do-colors('after');
}

.before-c {
    @include do-colors('before');
}

.hover-c {
    @include responsive() {
        @include do-colors('hover');
    }

    &-grad {
        @include do-grad-colors('hover');
    }
}
