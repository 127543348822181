.mh {
    @include responsive() {
        &-100vh {
            min-height: 100vh;
        }
        
        &-10rem {
            min-height: 10rem;
        }

        &-12rem {
            min-height: 12rem;
        }

        &-15rem {
            min-height: 15rem;
        }

        &-20rem {
            min-height: 20rem;
        }

        &-30rem {
            min-height: 30rem;
        }
    }
}
