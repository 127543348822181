@mixin do-border-colors($pseudo: '') {
    @each $name, $color in $colors {
        &-#{$name} {
            @if $pseudo == '' {
                border-color: $color;
            } @else {
                &:#{$pseudo} {
                    border-color: $color;
                }
            }
        }
    }
}

@mixin do-outline-colors($pseudo: '') {
    @each $name, $color in $colors {
        &-#{$name} {
            @if $pseudo == '' {
                outline-color: $color;
            } @else {
                &:#{$pseudo} {
                    outline-color: $color;
                }
            }
        }
    }
}

@mixin do-border-widths($short, $attr: '') {
    @each $name, $width in $border-widths {
        &-#{$short}-#{$name} {
            @if $attr != '' {
                border#{'-' + $attr}-width: $width;
            } @else {
                border-width: $width;
            }
        }
    }
}

@mixin do-outline-widths($short, $pseudo: '') {
    @each $name, $width in $border-widths {
        &-#{$short}-#{$name} {
            @if $pseudo == '' {
                outline-width: $width;
            } @else {
                &:#{$pseudo} {
                    outline-width: $width;
                }
            }
        }
    }
}

@mixin do-outline-offsets($pseudo: '') {
    @each $name, $width in $border-widths {
        &-#{$name} {
            @if $pseudo == '' {
                outline-offset: $width;
            } @else {
                &:#{$pseudo} {
                    outline-offset: $width;
                }
            }
        }
    }
}

.border {
    @include responsive() {
        border-style: solid;

        @include do-border-widths('w');
        @include do-border-widths('w-t', 'top');
        @include do-border-widths('w-r', 'right');
        @include do-border-widths('w-b', 'bottom');
        @include do-border-widths('w-l', 'left');

        &-c {
            @include do-border-colors();
        }
    }
}

.hover-border {
    @include responsive() {
        &-c {
            @include do-border-colors('hover');
        }
    }
}

.focus-border {
    @include responsive() {
        &-c {
            @include do-border-colors('focus');
        }
    }
}

.outline {
    @include responsive() {
        outline-style: solid;

        @include do-outline-widths('w');

        &-c {
            @include do-outline-colors();
        }

        &-offset {
            @include do-outline-offsets();
        }
    }
}

.hover-outline {
    &:hover {
        outline-style: solid;
    }

    @include do-outline-widths('w', 'hover');

    &-c {
        @include do-outline-colors('hover');
    }

    &-offset {
        @include do-outline-offsets('hover');
    }
}

.focus-outline {
    &:focus {
        outline-style: solid;
    }

    @include do-outline-widths('w', 'focus');

    &-c {
        @include do-outline-colors('focus');
    }

    &-offset {
        @include do-outline-offsets('focus');
    }
}
