.f-w {
    @include responsive() {
        &-2 {
            font-weight: $font-weight--lighter;
        }

        &-3 {
            font-weight: $font-weight--light;
        }

        &-4 {
            font-weight: $font-weight--default;
        }

        &-5 {
            font-weight: $font-weight--medium;
        }

        &-7 {
            font-weight: $font-weight--bold;
        }

        &-9 {
            font-weight: $font-weight--boldest;
        }
    }
}
