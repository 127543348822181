.obj-pos {
    &-t-c {
        object-position: top center;
    }

    &-t-l {
        object-position: top left;
    }

    &-t-r {
        object-position: top right;
    }
}
