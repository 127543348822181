.input,
.textarea {
    &::placeholder {
        font-size: $font-size--de;
        font-weight: $font-weight--medium;
    }
}

.ck.ck-editor__main {
    .ck-content {
        height: 30rem;
    }
}

