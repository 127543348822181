@mixin do-font-sizes($pseudo: '') {
    @each $name, $size in $font-sizes {
        &-#{$name} {
            @if $pseudo == '' {
                &,
                * {
                    font-size: $size;
                }
            } @else {
                @if $pseudo == 'nc' {
                    font-size: $size;
                } @else {
                    &:#{$pseudo} {
                        font-size: $size;
                    }
                }
            }
        }
    }
}

.f-s {
    @include responsive() {
        @include do-font-sizes();
    }
}

.nc-f-s {
    @include responsive() {
        @include do-font-sizes('nc');
    }
}


.after-f-s {
    @include responsive() {
        @include do-font-sizes('after');
    }
}

.before-f-s {
    @include responsive() {
        @include do-font-sizes('before');
    }
}
