/* iconfont */
$font-iconfont--code: '42712900';

/* family */
$font-family--default: 'Inter', sans-serif;
$font-family--arial: 'Arial', sans-serif;
$font-family--icons: 'fontello';

/* path */
$path-iconfonts: '/assets/font/iconfont/';

/* weight */
$font-weight--lighter: 200;
$font-weight--light: 300;
$font-weight--default: 400;
$font-weight--medium: 500;
$font-weight--bold: 700;
$font-weight--boldest: 900;

/* line-height */
$font-lh--1: 1;
$font-lh--1-2: 1.2;
$font-lh--1-6: 1.6;
$font-lh--2: 2;

/* size */
$font-size--9: .563rem;
$font-size--10: .625rem;
$font-size--12: .75rem;
$font-size--de: .875rem;
$font-size--16: 1rem;
$font-size--18: 1.125rem;
$font-size--20: 1.25rem;
$font-size--22: 1.375rem;
$font-size--24: 1.5rem;
$font-size--36: 2.25rem;
$font-size--64: 4rem;

$font-sizes: (
    '9': $font-size--9,
    '10': $font-size--10,
    '12': $font-size--12,
    'de': $font-size--de,
    '16': $font-size--16,
    '18': $font-size--18,
    '20': $font-size--20,
    '22': $font-size--22,
    '24': $font-size--24,
    '36': $font-size--36,
    '64': $font-size--64,
    'h1': $dimensions-headline--h1,
    'h2': $dimensions-headline--h2,
    'h3': $dimensions-headline--h3,
    'h4': $dimensions-headline--h4,
    'h5': $dimensions-headline--h5,
    'h6': $dimensions-headline--h6
);
