.a {
    @include responsive() {
        &-c {
            text-align: center;
        }

        &-l {
            text-align: left;
        }

        &-r {
            text-align: right;
        }

        &-margin {
            margin: 0 auto;
        }

        &-items {
            &-s {
                justify-self: start;
            }

            &-e {
                justify-self: end;
            }

            &-c {
                justify-self: center;
            }

            &-stretch {
                justify-self: stretch;
            }
        }

        &-ji {
            &-s {
                justify-self: start;
            }

            &-e {
                justify-self: end;
            }

            &-c {
                justify-self: center;
            }

            &-stretch {
                justify-self: stretch;
            }
        }

        &-js {
            &-s {
                justify-self: start;
            }

            &-e {
                justify-self: end;
            }

            &-c {
                justify-self: center;
            }

            &-stretch {
                justify-self: stretch;
            }
        }

        &-self {
            &-s {
                align-self: start;
            }

            &-e {
                align-self: end;
            }

            &-c {
                align-self: center;
            }

            &-stretch {
                align-self: stretch;
            }
        }
    }
}
