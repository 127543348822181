.complex {
    &-treesel {
        width: 100%;
        
        .react-dropdown-tree-select {
            width: 100%;
            
            .dropdown {
                width: 100%;
                
                .dropdown-trigger {
                    align-items: center;
                    display: flex;
                    width: 100%;
                }
    
                .tag-list {
                    width: 100%;
                }
    
                .tag-item {
                    &:last-child {
                        width: 100%;
                    }
        
                    .search {
                        width: 100%;
                    }
                }
    
                .dropdown-content {
                    width: 100%;
                }
    
                .toggle {
                    font-size: $font-size--24;
                    font-style: normal;
                    
                    &.expanded,
                    &.collapsed {
                        &:after {
                            padding: $margin--6;
                        }
                    }
                }
                
                .infinite-scroll-component {
                    max-height: 30rem;
                }
                
                .node {
                    align-items: center;
                    display: flex;
                    
                    label {
                        width: 100%;
                    }
                }
            }
        }
    }
}
