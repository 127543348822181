.font {
    &-ul {
        text-decoration: underline;
    }

    &-ws {
        &-nowrap {
            white-space: nowrap;
        }
    }
    
    &-space {
        &-de {
            letter-spacing: inherit;
        }
    }
    
    &-trans {
        &-upper {
            text-transform: uppercase;
        }
    }
}

.hover-font {
    &-ul {
        &:hover {
            text-decoration: underline;
        }
    }

    &-decoration-none {
        &:hover {
            text-decoration: none;
        }
    }
}
