.complex {
    &-logo {
        .svgbg {
            fill: transparent;
        }
        
        .svgg,
        .svgw {
            animation: logoBackend 2s;
            fill: $color--primary-1;
        }
        
        &:hover {
            .svgg,
            .svgw {
                fill: $color--primary-3;
            }
        }
    }
}
