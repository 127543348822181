.complex {
    &-acc {
        button {
            .is--active & {
                color: $color--primary-3;
            }
        }

        &-title {
            &:after {
                font-size: $font-size--18;
                padding: $margin--6;
                transform: rotate(90deg);
                transition: transform $transition-default;

                .is--active & {
                    transform: rotate(-90deg);
                }
            }

            &:hover {
                cursor: pointer;

                button {
                    color: $color--primary-3;
                }
            }
        }

        &-con {
            max-height: 0;
            transition: max-height $transition-default, padding $transition-default;

            .is--active & {
                max-height: inherit;
            }
        }
    }
}
