.text {
    a {
        &[href] {
            &:not(.is--normal) {
                color: $color--primary-3;
                font-weight: $font-weight--medium;
                text-decoration: none;
                transition: color $transition-default;
            }

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    p {
        line-height: $font-lh--2;
        margin-bottom: $margin--10;
    }

    p,
    a,
    ul,
    ol,
    li {
        font-size: $font-size--18;
    }

    h3 {
        &,
        * {
            font-size: $font-size--36;
            font-weight: $font-weight--boldest;
            margin: $margin--70 0 $margin--38;
        }
    }

    ul,
    ol {
        line-height: $font-lh--1-6;
        list-style-position: inside !important;
        margin-bottom: $margin--10;
    }

    ul {
        list-style: disc;

        &.is--icon {
            list-style: none;

            li {
                &:before {
                    padding-right: $margin--6;
                }
            }
        }
    }

    ol {
        list-style-type: decimal;
    }

    b,
    strong {
        font-weight: $font-weight--bold;
    }

    img {
        padding: $margin--16;
    }

    #intro {
        background-color: $color--primary-4;
        border-radius: $dimensions-border-radius--6;
        display: inline-block;
        margin: $margin--10 0;
        padding: $margin--16;
    }

    #conclusion {
        background-color: $color--primary-4;
        border-radius: $dimensions-border-radius--6;
        display: inline-block;
        margin: $margin--10 0;
        padding: $margin--16;
    }

    blockquote {
        @include icon(before, 'quote-left');
        @include position(relative);
        align-items: center;
        background: $color--white;
        border-left: .25rem solid darken($color--white, 5%);
        display: flex;
        margin: $margin--6;
        padding: $margin--6;
        padding-left: 2rem;

        &:before {
            @include position(absolute, $top: 50%, $left: 0);
            color: darken($color--white, 5%);
            font-size: 1em;
            padding: $margin--6;
            transform: translateY(-50%);
        }

        p {
            display: inline;
        }
    }

    &-individually {
        p,
        h1,
        h2,
        h3,
        h4 {
            line-height: $font-lh--2;
            margin-bottom: $margin--10;
        }
    }
    
    &-small {
        * {
            font-size: $font-size--12;
        }
    
        p,
        h1,
        h2,
        h3,
        h4 {
            margin-bottom: $margin--10;
        }
    }

    &-cleanhtml {
        font-size: $font-size--16;
        line-height: $font-lh--2;

        h2,
        h3,
        h4 {
            &,
            * {
                font-size: $font-size--24;
                margin: $margin--24 0 $margin--16;
            }
        }
    }

    table {
        td {
            font-size: $font-size--de;
            padding: $margin--8;
        }

        tr {
            &:nth-of-type(2n) {
                background-color: $color-border--grey;
            }

            &:first-of-type {
                td {
                    font-weight: $font-weight--bold;
                }
            }
        }
    }
}

.c-text {
    &.is--c-white {
        * {
            color: $color--white;
        }
    }

    &.is--8 {
        display: inline-block;
    }

    &.is--6 {
        display: inline-block;
    }

    &.is--block {
        text-align: justify;
    }


    &.is--light {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: $font-size--16;
            font-weight: $font-weight--default;
        }

        strong {
            font-weight: $font-weight--medium;
        }

        h3,
        h4,
        h5,
        h6 {
            font-size: $font-size--18;
        }
    }

    [class*='is--special-'] {
        @include position(relative);
        display: inline-block;
        margin-bottom: $margin--6;

        &.is--special-opinion {
            @include icon(before, 'comment');
            background-color: $color--primary-4;
        }

        &.is--special-tip {
            @include icon(before, 'lightbulb');
            background-color: $color--primary-4;
        }

        &.is--special-attention {
            @include icon(before, 'attention');
            background-color: lighten($color-status--warning, 20%);
        }

        &.is--special-opinion,
        &.is--special-tip,
        &.is--special-attention {
            &:before {
                @include position(absolute, $top: 50%, $left: 0);
                color: $color-font;
                font-size: $font-size--24;
                padding: $margin--10;
                transform: translateY(-50%);
            }
        }
    }
}
