/*
 * ### collects all icon fonts
 */

// ==========================================================================
// Iconfonts :: Fontello
// ==========================================================================
@font-face {
    font-display: swap;
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    src: url($path-iconfonts + 'fontello.woff2?' + $font-iconfont--code) format('woff2'),
    url($path-iconfonts + 'fontello.woff?' + $font-iconfont--code) format('woff'),
    url($path-iconfonts + 'fontello.ttf?' + $font-iconfont--code) format('truetype'),
    url($path-iconfonts + 'fontello.svg?' + $font-iconfont--code + '#fontello') format('svg');
}
