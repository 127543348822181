.shad {
    @include responsive() {
        &-strong {
            box-shadow: 0 15px 30px 0 $shadow-color-strong;
        }

        &-xsm {
            box-shadow: 0 7px 16px 0 $shadow-color-xsm;
        }

        &-sm {
            box-shadow: 0 0 3px 0 $shadow-color-sm;
        }

        &-line {
            box-shadow: 0 0 6px $color--transparent-1;
        }

        &-action {
            box-shadow: 0 0 0 1px $shadow-color-action;
        }
    
        &-none {
            box-shadow: none;
        }
    }
}

.hover-shad {
    @include responsive() {
        &-strong {
            &:hover {
                box-shadow: 0 15px 30px 0 $shadow-color-strong;
            }
        }

        &-xsm {
            &:hover {
                box-shadow: 0 7px 16px 0 $shadow-color-xsm;
            }
        }

        &-sm {
            &:hover {
                box-shadow: 0 0 3px 0 $shadow-color-sm;
            }
        }
    }
}
