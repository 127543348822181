.complex {
    &-menu {
        &-ul {
            &:after {
                @include position(absolute, $top: 100%, $left: 0);
                background-color: $color--primary-1;
                content: ' ';
                display: block;
                height: .125rem;
                transform: translateY(-.125rem);
                width: 100%;
            }
        }
    }
}
