// sass-lint:disable-all
// ==========================================================================
// Mixin :: iconfont

@mixin icon($position: before, $icon: false) {
    @if $position == both {
        $position: 'before, &:after';
    }
    &:#{$position} {
        @if $icon {
            content: "#{map-get($icons, $icon)}";
        }
        @content;
    }
}
// sass-lint:enable-all
