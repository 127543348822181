.img {
    &-object-fit {
        &-cover {
            object-fit: cover;
        }

        &-sd {
            object-fit: scale-down;
        }
    }
    
    &-object-pos {
        &-lt {
            object-position: left top;
        }

        &-cc {
            object-position: center center;
        }
    }
}
